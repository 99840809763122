import { useEffect, useRef } from 'react';

import { variables } from './../';

import { useConfig } from 'utilities/variables/configContext';
import {EXP_ENVIRONMENT } from 'index';
import { api } from 'utilities'; // <-- Adjust path as needed


export const getReadableProductLocation = (region: string): string => {
  return variables.DEFAULT_AWS_LOCATIONS[region] || 'REGION N/A';
}

export const useInterval = (callback: any, delay: number) => {

  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const stringToTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}



export const getCurrentDate = (separator='') => {

  let newDate = new Date()
  let day = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();
  
  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${day<10?`0${day}`:`${day}`}`
}

export const getCurrentTime = () => {
  const current = new Date();

  // Getting the hours and minutes
  const hours = current.getHours().toString().padStart(2, '0');
  const minutes = current.getMinutes().toString().padStart(2, '0');

  // Getting the date, month, and year
  // const date = current.getDate().toString().padStart(2, '0');
  // const month = (current.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are 0-indexed
  // const year = current.getFullYear().toString().slice(2, 4); // Getting the last two digits of the year

  // Formatting the date and time as per HH:MM DD/MM/YY
  return `${hours}:${minutes}`;
}

export const getConditionalDateTime = (unixtimeStamp:number, dateFlag = true, timeFlag = false, multiplyFlag = true) => {
  const currentTime = new Date();
  const passedTime = new Date(unixtimeStamp * (multiplyFlag ? 1000 : 1));

  if (currentTime.getDate() === passedTime.getDate()) {
    const time = `${passedTime.getHours().toString().padStart(2, '0')}:${passedTime.getMinutes().toString().padStart(2, '0')}`;
    return(time);
  } else {
    const date = `${passedTime.getMonth() + 1}/${passedTime.getDate()}/${passedTime.getFullYear()}`;
    if (timeFlag) {
      const time = `${passedTime.getHours().toString().padStart(2, '0')}:${passedTime.getMinutes().toString().padStart(2, '0')}`;
      const timeDate = `${time} ${date}`
      return (timeDate);
    } else {
      return (date);
    }
  }
};


export const removeNote = (elementID: string) => {
  const ele = document.getElementById(elementID);
  ele?.remove();
}


export const hideContent = () => {
  var privContent = document.getElementById('priv-content');

  if(privContent?.classList.contains('hideContent')) {
    document.getElementById('priv-content')?.classList.remove('hideContent');
  } else {
    document.getElementById('priv-content')?.classList.add('hideContent');
  }
}

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else {
    logToOutput('Cannot Exit Fullscreen');
  }
}

export const enterFullScreen = (elementID: string) => {
  var element = document.getElementById(elementID);
  if (element != null) {
  //("Found Element " +  iFrameID);
    if(element.requestFullscreen){
      element.requestFullscreen();
    }
  } else {
    logToOutput('Cannot find element',  elementID);
  }
}

export const popNewWindow = (link: URL, elementID: string) => {
  window.open(link);
  const iFrame = document.getElementById(elementID);
  iFrame?.remove();
}

export const showDropDown = (id: string) => {
  var element = document.getElementById(id);

  if(element?.classList.contains('showDropdown')) {
    document.getElementById(id)?.classList.remove('showDropdown');
  } else {
    document.getElementById(id)?.classList.add('showDropdown');
  }
}


export const getRedableProductType = (type: string) => {
  return variables.DEFAULT_READABLE_PRODUCT_TYPES[type]?.NAME
}


/**
 * 
 * @param rawObject Object to be Filtered
 * @param keysToRemove Keys to be removed
 * @returns 
 */
export const filterObjectByRemovingKeys = (rawObject: any, keysToRemove: string[]) =>  {
  return Object.keys(rawObject)
    .filter(key => !keysToRemove.includes(key))
    .reduce((filteredObject: any, key: string) => {
      filteredObject[key] = rawObject[key];
      return filteredObject;
    }, {});
}

export const generateProductUrl = (productCode: string, id: string): string => {

  const domainName = window.location.hostname;
  const [prodCloud, prodRegion, prodType, prodSubType, prodSubTypeVersion, prodMetaDataOne, prodMetaDataTwo] = productCode.split('_');

  let urlToReturn = '';

  switch (prodType) {
  case 'vtc':
    // prodUrl = "https://"+props.product.id+"."+prodType+"."+config.DOMAIN_URL;
    urlToReturn = `https://${id}.${prodType}.${domainName}`;
    break;


  default:
    urlToReturn = `https://${domainName}/connect/${prodType}/${id}`;
    break;
  }

  return urlToReturn;
} 

// ...optionalParams: any[]
export const logToOutput = (...logStatements: any[]): void => {
  // no logs in production
  if(EXP_ENVIRONMENT !== 'PRODUCTION'){
  // eslint-disable-next-line no-console
    console.log(...logStatements);
  }
}

export const getProductMeta = async (key_value: string, identifier: string) => {
  const query = {
    id: identifier,
    body: {
      key: key_value,
    },
  };

  try {
    const result = await api.getProductMeta(query);
    
    return {
      status: 'ok',
      response: result.body.responseObj,
    };
  } catch (error) {
    return {
      status: 'error',
      response: error,
    };
  }
};

export const isValidUrl = (url: string): boolean => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator
    'i'
  );
  return !!urlPattern.test(url);
};



// export async function createVaultKeysForSecurityGroup(securityGroupID: string) { 

//   const getSecurityGroupResult = await api.getSecurityGroup({body: {id: securityGroupID}});

//   let usernamesAndPublicKeys: Array<UsernamePublicKey> = [];

//   if(getSecurityGroupResult.users?.length > 0) {
//     for (const user of getSecurityGroupResult.users) {
//       const getUserKeysResult = await api.getUserKeys({ username: user });
//       const publicKey = getUserKeysResult.data?.public_key ? getUserKeysResult.data.public_key : getUserKeysResult.data
//       let publicKeyAsCryptoKey = await globalThis.crypto.subtle.importKey(
//         "spki",
//         bsgCrypto.base64ToArrayBuffer(publicKey),
//         { name: "RSA-OAEP", hash: "SHA-256" },
//         true,
//         ["wrapKey", "encrypt"]
//       );

//       usernamesAndPublicKeys.push({username: user, publicKey: publicKeyAsCryptoKey});
//     }
//   }

//   return await bsgCrypto.VaultKey.createVaultKey(usernamesAndPublicKeys);
// }

// export async function checkAndAddMissingUserVaultKeys(resourceId: string, resourceType: string, vault: bsgCrypto.Vault) {
//   const usersWithoutKeys = await api.getUsersWithoutKeys({
//     resourceType: resourceType,
//     resourceId: resourceId
//   });

//   if(usersWithoutKeys.result !== "Success") {
//     return;
//   }

//   if (usersWithoutKeys.data.result.length > 0) {

//     let vaultKeys: Record<string, string> = {};

//     for (const user of usersWithoutKeys.data.result) {
//       const publicKey = await api.getUserKeys({ username: user });
//       const wrappedVaultKey = await vault.wrapVaultKeyForUser(publicKey.data);
//       vaultKeys[user] = wrappedVaultKey;
//     }

//     const patchingUserVaultKeysResult = await api.patchUserVaultKeys({
//       resourceType: resourceType,
//       resourceId: resourceId,
//       body: {
//         vault_keys: vaultKeys
//       }
//     });

//     if(patchingUserVaultKeysResult.result !== "Success") {
//     }
//   }
// }

