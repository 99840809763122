// OpenConfigContext.tsx
import React, { createContext, useContext } from 'react';
import { types } from 'utilities';


// 1) Shape of your open config data


// 2) Shape of what you provide via context
interface IOpenConfigContext {
  config: types.IOpenConfig | null;
}

const OpenConfigContext = createContext<IOpenConfigContext | undefined>(undefined);

interface IOpenConfigProviderProps {
  config: types.IOpenConfig;
  children: React.ReactNode;
}

// 3) Provider to accept the fetched config and store it
export const OpenConfigProvider: React.FC<IOpenConfigProviderProps> = ({ config, children }) => {
  return (
    <OpenConfigContext.Provider value={{ config }}>
      {children}
    </OpenConfigContext.Provider>
  );
};

// 4) Hook to consume the open config
export const useOpenConfig = (): IOpenConfigContext => {
  const context = useContext(OpenConfigContext);
  if (!context) {
    throw new Error('useOpenConfig must be used within an OpenConfigProvider');
  }
  return context;
};
