import React from 'react';

const MobileSite: React.FC = () => {
  return (
    <div className="mobile-site">
      <header className="mobile-header">
        <h1>Mobile Site</h1>
      </header>
      <main className="mobile-content">
        <p>Welcome to the mobile version of the site.</p>
        {/* Add more mobile-specific content here */}
      </main>
      <footer className="mobile-footer">
        <p>Mobile Footer</p>
      </footer>
    </div>
  );
};

export default MobileSite;