
import React, { useState, useEffect } from 'react';
import { Row, Col, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { types, helpers, variables } from 'utilities';
import { bots } from 'components';
import { useConfig } from 'utilities/variables/configContext';

import {
  stopProduct,
  startProduct,
  pauseProduct,
  dotsIcon,
  closeBtn,
} from './../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageBot, faSliders } from '@fortawesome/pro-regular-svg-icons';



const ProductWindowMenuControls: React.FC<types.IProductWindowMenuControls> = ({ 
  product, isRunning, isLoading, isOverlayVisible, menuTab, isUserAuthed,
  handleProductPause, handleProductStop, handleProductStart, handleProductClose, 
  handleShowProductTerminateModal, handleShowProductPresignedLinkModal, handleShowProductUserShareModal, handleShowProductGroupShareModal,
  handleToggleOverlayVisible, handleShowProductSetScreenSizeModal, handleShowProductSetSecurityGroupModal, 
  handleShowProductShowPasswordModal, handleMenuTabChange
}) => {

  
  const { config } = useConfig();
  const hasAndroid = product?.['product-code']?.includes('android') ?? false;
  const hasKali = product?.['product-code']?.includes('kali') ?? false;
  

  useEffect(() => {
    
  }, [product]);
  
  // helpers.logToOutput('ViewportMenu : url: ', url)
  // helpers.logToOutput('ViewportMenu : prodIndex: ', vpProductIndex)
  const topActiveStyle = { color: 'white', backgroundColor: '#d7d7d7', borderRadius: '5px 0px 0px 0px' };
  const bottomActiveStyle = { color: 'white', backgroundColor: '#d7d7d7', borderRadius: '0px 0px 0px 5px' };
  
  const topInactiveStyle = { color: 'black', backgroundColor: 'white', borderRadius: '5px 0px 0px 0px' };
  const bottomInactiveStyle = { color: 'black', backgroundColor: 'white', borderRadius: '0px 0px 0px 5px' };

  
  if(product && product.id){
    return (
      <OverlayTrigger show={isOverlayVisible} key={`oTrig-${product.id.toString()}`} placement="left-start"  overlay={
        <Row className=''>
          <Col className='col-1 m-0 p-0 k-mt-80'>
            <Nav variant="" activeKey={menuTab} onSelect={handleMenuTabChange}>
              <Nav.Item className=''>
                <Nav.Link className='p-2 k-min-w-35' eventKey="chat" style={menuTab === 'chat' ? topActiveStyle : topInactiveStyle} >
                  <FontAwesomeIcon icon={faMessageBot} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className=''>
                <Nav.Link className='p-2 k-min-w-35' eventKey="settings" style={menuTab === 'settings' ? bottomActiveStyle : bottomInactiveStyle}>
                  {/*<FontAwesomeIcon icon={icon({name: 'message-bot', family: 'classic', style: 'solid'})} style={{color: '#d7d8db',}}/>*/}
                  <FontAwesomeIcon icon={faSliders} />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          {menuTab === 'settings' ? (
            <Col className='col-11 m-0 p-0'>
              <Popover id={`popover-positioned-${product && product.id && product.id.toString()}`}  className='k-z-ind-99999 k-no-pop-arrow k-mgn-l-8 k-min-w-200 k-mt-40 k-mr-m-50'>
                <Row className='m-0 p-0'>
                  <Col className='col-12 m-0 p-0'>
                    <Popover.Header as="h3">
                      <Row>
                        <Col className='col-10 k-panel-cent-vert'>Control Panel</Col>
                        <Col className='col-2 k-panel-cent-vert k-center-content-hor-right'>
                          <div>
                            <OverlayTrigger
                              trigger="hover"
                              placement="top"
                              delay={{ show: 1000, hide: 200 }} // Add delay here
                              overlay={
                                <Popover id={`popover-m-${product.id.toString()}`} className='k-z-ind-popup b-rad-10'>
                                  <Popover.Body className='p-1 m-0 txt-weight-200 txt-12 text-center  k-z-ind-popup'>
                                    Click to close the menu
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img className='k-mh-20 k-opac-50pc k-pointer' onClick={handleToggleOverlayVisible} src={closeBtn}></img>
                            </OverlayTrigger>
                          </div>
                          
                        </Col>
                      </Row>
                    </Popover.Header>
                    <Popover.Body className='p-0'>
                      <Row className='m-0 p-0'>

                        <Col className='col-12 m-0 p-0'>
                          <Nav>
                            <span className='ps-2 ms-2 mb-1 mt-1 txt-10 txt-bold color-lightText'>View</span>
                            <NavDropdown.Item eventKey="4" className='p-2 ps-3 ms-2 me-2 mb-2 k-hover-grey-bg txt-12 b-rad-5' onClick={handleProductClose}>
                              <Row>
                                <Col className='col-8 k-panel-cent-vert txt-10'>
                                    Close
                                </Col>
                                {/* <Col className='col-4'>
                                    {isLoading ? (
                                      <div className='k-center-content-hor-right'>
                                        <Spinner animation="border" role="status" size="sm">
                                          <span className="visually-hidden">Closing...</span>
                                        </Spinner>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Col> */}
                              </Row>
                            </NavDropdown.Item>

                            <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>

                            <span className='ps-2 ms-2 mb-1 mt-1 txt-10 txt-bold color-lightText'>Share</span>
                            <NavDropdown.Item
                              eventKey="1"
                              style={{opacity: product.state==='running' ? 1 :  0.2, cursor: product.state==='running' ? 'pointer' : 'default'}}
                              className={'p-2 ps-3 ms-2 me-2 txt-10 b-rad-5 k-hover-grey-bg'}
                              onClick={handleShowProductPresignedLinkModal}>
                                    Generate share link
                            </NavDropdown.Item>
                            <NavDropdown.Item eventKey="2" className='p-2 ps-3 ms-2 me-2 pt-1 k-hover-grey-bg txt-10 b-rad-5' onClick={handleShowProductUserShareModal}>
                                Share with a user
                            </NavDropdown.Item>

                            { config && config.GROUPS ? (
                              <NavDropdown.Item eventKey="2" className='p-2 ps-3 ms-2 me-2 mb-2 pt-1 k-hover-grey-bg txt-10 b-rad-5' onClick={handleShowProductGroupShareModal}>
                                  Share with a group
                              </NavDropdown.Item>
                            ) : (<></>)}

                            <hr className='k-border-top-grey w-100-pc mt-1 mb-1'/>
                            <NavDropdown.Item 
                              eventKey="782" 
                              className={'p-2 ps-3 ms-2 me-2 txt-10 b-rad-5 k-hover-grey-bg'} 
                              onClick={() => {handleShowProductShowPasswordModal(true)}}
                            >
                              Show Password
                            </NavDropdown.Item>
                            
                            <NavDropdown.Item 
                              eventKey="792" 
                              className={'p-2 ps-3 ms-2 me-2 txt-10 b-rad-5 k-hover-grey-bg'} 
                              onClick={() => {handleShowProductSetScreenSizeModal(true)}}
                            >
                              Set Desktop Size
                            </NavDropdown.Item>
                            
                            
                            {/*<NavDropdown.Item 
                              eventKey="802" 
                              className={'p-2 ps-3 ms-2 me-2 txt-10 b-rad-5 k-hover-grey-bg'} 
                              onClick={() => {handleShowProductSetSecurityGroupModal(true)}}
                            >
                              Set Security Group
                            </NavDropdown.Item>*/}
                            
                            <NavDropdown.Item eventKey="3" className='p-2 ps-3 ms-2 me-2 mb-0 k-hover-grey-bg txt-10 b-rad-5'>
                              <Row>
                                <Col className='col-8 k-panel-cent-vert'>
                                    Device Control 
                                </Col>
                                <Col className='col-4'>
                                  { isLoading ? 
                                    (<div className='k-center-content-hor-right'>
                                      <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Loading...</span>
                                      </Spinner>
                                    </div>)
                                    :
                                    (<div className='k-center-content-hor-right k-panel-cent-vert'>
                                      <div>
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement="top"
                                          delay={{ show: 1000, hide: 200 }} // Add delay here
                                          overlay={
                                            <Popover id={`popover-${product.id.toString()}`} className='k-z-ind-popup b-rad-10'>
                                              <Popover.Body className='p-1 m-0 txt-weight-200 txt-12 text-center '>
                                                Click to start the product
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <img style={{opacity: isRunning ? 0.2: 1 , cursor: isRunning ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' src={startProduct} onClick={handleProductStart}></img>
                                        </OverlayTrigger>
                                      </div>
                                      <div>
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement="top"
                                          delay={{ show: 1000, hide: 200 }} // Add delay here
                                          overlay={
                                            <Popover id={`popover-${product.id.toString()}`} className='k-z-ind-popup b-rad-10'>
                                              <Popover.Body className='p-1 m-0 txt-weight-200 txt-12 text-center '>
                                                Click to hibernate the product
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <img style={{opacity: !isRunning || hasAndroid || hasKali ? 0.2 : 1, cursor: !isRunning || hasAndroid || hasKali ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' src={pauseProduct} onClick={hasKali ? null : handleProductPause}/>
                                        </OverlayTrigger>
                                      </div>
                                      <div>
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement="top"
                                          delay={{ show: 1000, hide: 200 }} // Add delay here
                                          overlay={
                                            <Popover id={`popover-${product.id.toString()}`} className='k-z-ind-popup b-rad-10'>
                                              <Popover.Body className='p-1 m-0 txt-weight-200 txt-12 text-center '>
                                                Click to stop the product
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <img style={{opacity: !isRunning ? 0.2 : 1, cursor: !isRunning ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-0 k-pointer-item' src={stopProduct} onClick={handleProductStop}></img>
                                        </OverlayTrigger>
                                      </div>
                                    </div>)
                                  }
                                </Col>
                              </Row>
                            </NavDropdown.Item>

                            <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                            <NavDropdown.Item 
                              eventKey="5" 
                              className='p-2 ps-3 ms-2 me-2 mb-2 k-hover-grey-bg txt-10 b-rad-5' 
                              style={{opacity: product.state==='building' ? 0.2 :  1, cursor: product.state==='building' ? 'default' : 'pointer'}}
                              onClick={product.state !== 'building' ? handleShowProductTerminateModal : undefined}
                            >
                              <Row>
                                <Col className='col-8 k-panel-cent-vert'>
                                    Terminate instance 
                                </Col>
                              </Row>
                            </NavDropdown.Item>
                          </Nav>
                        </Col>
                      </Row>
                    </Popover.Body>
                  </Col>
                </Row>
              </Popover>
            </Col>
          ) : menuTab === 'chat' ? (
            <Col className='col-11 m-0 p-0'>
              <Popover id={`popover-positioned-${product && product.id && product.id.toString()}`} className='k-z-ind-99999 k-no-pop-arrow k-mgn-l-8 k-min-w-200 k-mt-40 k-mr-m-50'> 
                <Row className='m-0 p-0'>
                  <Popover.Header as="h3">
                    <Row>
                      <Col className='col-10 k-panel-cent-vert'>Chat</Col>
                      <Col className='col-2 k-panel-cent-vert k-center-content-hor-right'>
                        <img className='k-mh-20 k-opac-50pc k-pointer' onClick={handleToggleOverlayVisible} src={closeBtn}></img>
                      </Col>
                    </Row>
                  </Popover.Header>
                  <Popover.Body className='p-0'>
                    <Row className='m-0 p-0 k-w-274'>
                      <Col className='col-12 m-0 p-0'>
                        <bots.ProductWindowChatBotPanel productId={product && product.id} isUserAuthed={isUserAuthed}/>
                      </Col>
                    </Row>
                  </Popover.Body>
                </Row>
              </Popover>
            </Col>
          ) : null}
        </Row>
      }>
        <Button variant='link' className="k-bg-trans m-0 p-0 k-border-none k-center-content-hor-right k-no-button">
          <OverlayTrigger
            trigger="hover"
            placement="left"
            delay={{ show: 1000, hide: 200 }} // Add delay here
            overlay={
              <Popover id={`popover-${product.id}`} className='b-rad-10'>
                <Popover.Body className='p-1 m-0 txt-weight-200 txt-12 text-center '>
                  Click to open the control panel
                </Popover.Body>
              </Popover>
            }
          >
            <img id="showProdDragPopover" className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' onClick={handleToggleOverlayVisible} src={dotsIcon}></img>
          </OverlayTrigger>
        </Button>
      </OverlayTrigger>
    );
  } else {
    return null
  }
}

const ChatMenuPopup: React.FC<types.IntMenuPopup> = ({ menuData, isUserAuthed }) => {
  // Declare a state variable to hold the authContext value
  const [eventPassed, setEventPassed] = useState(menuData.data.event); 
  const [devID, setDevID] = useState(menuData.data.deviceID); 


  const handleContextMenuCopy = () => {
    navigator.clipboard.writeText(eventPassed.target.innerText)
      .then(() => {
        helpers.logToOutput('Text Copied');
      })
      .catch(err => {
        helpers.logToOutput('Failed to copy text: ', err);
      });
  };

  const handleContextMenuSettings = () => {
    //Show Settings Modal
  };

    
  return (
    <>
      { isUserAuthed ?
        (<>
                    
          <Popover id={'popover-nav-1'} className='k-z-ind-999999 k-no-pop-arrow k-w-230 k-menu-white-bg  mt-0'>

            <Popover.Body className='p-0 pb-2'>
              <div className='p-0 m-0 pt-2'>
                
                <Row className='p-0 m-0'><Col className='col-12'><div className='p-1 ps-1 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-11 b-rad-5' onClick={null}>Open in new window</div></Col></Row>

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                <Row className='p-0 m-0'><Col className='col-12'><div className='p-1 ps-1 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-11 b-rad-5' onClick={handleContextMenuCopy}>Copy</div></Col></Row>
                <Row className='p-0 m-0'><Col className='col-12'><div className='p-1 ps-1 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-11 b-rad-5' onClick={menuData.data.clearMsgCallback}>Clear Session</div></Col></Row>

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                <Row className='p-0 m-0'><Col className='col-12'><div className='p-1 ps-1 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-11 b-rad-5' onClick={handleContextMenuSettings}>Chat Settings</div></Col></Row>
                                        
              </div>
            </Popover.Body>
          </Popover>
        </>)
        :
        (<>
                    
        </>)
      }
    </>
  )
};

export {
  ProductWindowMenuControls,
  ChatMenuPopup
}