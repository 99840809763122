// ConfigContext.tsx
import React, { createContext, ReactNode, useContext, useState, useCallback } from 'react';
import { api, variables, types } from 'utilities';

interface IConfigContext {
  config: types.DeploymentSettings | null;
  loading: boolean;
  error: string | null;
  fetchConfig: () => Promise<void>;  // <-- new method to load config on demand
}

const ConfigContext = createContext<IConfigContext | undefined>(undefined);

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<types.DeploymentSettings | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchConfig = useCallback(async () => {
    // Prevent multiple fetches if already loading or loaded
    if (loading || config) return;
    setLoading(true);
    setError(null);

    try {
      const response = await api.getConfiguration({});
      if (response.status === variables.DEFAULT_API_RESULT.SUCCESS) {
        setConfig(response.body);
      } else {
        setError(response.errorMessage || 'Failed to fetch configuration.');
      }
    } catch (err: any) {
      setError(err?.message ?? 'An unknown error occurred.');
    } finally {
      setLoading(false);
    }
  }, [loading, config]);

  const value: IConfigContext = {
    config,
    loading,
    error,
    fetchConfig
  };

  return (
    <ConfigContext.Provider value={value}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): IConfigContext => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};
