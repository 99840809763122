import { useEffect } from 'react';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';

// routes
import Routes from './routes';
import { variables } from './utilities';

// Services
// import { webSocket } from './utilities/services';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.css';

import { useOpenConfig } from 'utilities/variables/openConfigContext';

const App: React.FC = () => {

  const { config } = useOpenConfig();
  useEffect(() => {
    AOS.init();
    if (config) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement;

      if (favicon) {
        if ( config.BRANDING === 'GENERIC') {
          favicon.href = process.env.PUBLIC_URL + '/favicon-standard.png'
        } else if (config.BRANDING === 'KURO') {
          favicon.href = process.env.PUBLIC_URL + '/favicon-other.png';
        } else {
          favicon.href = process.env.PUBLIC_URL + ''
        }
      }
      if ( config.BRANDING === 'GENERIC') {
        document.title = 'Intelligent Cloud'
      } else if (config.BRANDING === 'KURO') {
        document.title = 'Kuro'
      } else {
        document.title = ''
      }
    }

  }, []);

    
  // const applicationCleanup = () => {
  //   // webSocket.detachWebSocket();
  // }
  return (
    <>
      <ToastContainer
        style={{ zIndex: '1000000' }} // Above current nav z-index 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />
      <Routes />
    </>
  );
};

export default App;
