import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Footermain, Topnav } from 'components';
import { authContextHelper } from 'utilities';
import RouteDefinitions from './RouteDefinitions';
import MobileSite from '../mobile'; // Import your mobile site component

const Routes: React.FC = () => {
  const [showSearchBar, setShowTopSearchBar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const userAgent = navigator.userAgent;
      const isMobileDevice = /android|iPad|iPhone|iPod/i.test(userAgent);
      setIsMobile(isMobileDevice);
    };

    handleResize(); // Check the initial width and user agent
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <authContextHelper.AuthProvider>
          <div className="app">
            {isMobile ? (
              <MobileSite />
            ) : (
              <>
                <Topnav
                  navClass="bg-white navbar-light k-z-ind-10"
                  isSticky={true}
                  showTopSearchBar={showSearchBar}
                />
                <div className='k-fg-1'>
                  <RouteDefinitions setShowTopSearchBar={setShowTopSearchBar} />
                </div>
                {/*<Footermain />*/}
              </>
            )}
          </div>
        </authContextHelper.AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default Routes;