// types
import { Project, Task } from './types';

// images
import member1 from 'assets/images/avatars/img-8.jpg';
import member2 from 'assets/images/avatars/img-5.jpg';
// import member3 from 'assets/images/avatars/img-7.jpg';

import { types } from './../../../utilities';
import {
  vmLogo,
  androidLogo,
  androidaws,
  androidazure,
  windowsLogo,
  windowsAWSLogo,
  windowsAZURELogo,
  windowsOsintLogo,
  ubuntuLogo,
  ubuntuAWSLogo,
  ubuntuAZURELogo,
  kaliLogo,
  parrotLogo,
  reactOSLogo,
  blackArchLogo,
  siftLogo,
  rocketchatLogo,
  jitsiLogo,
  mandiantFlareLogo,
  selkLogo,
  arkimeLogo,
  prodTime24,
  prodTime10,
  prodTime9,
  prodTime8,
  prodTime7,
  prodTime6,
  prodTime5,
  prodTime4,
  prodTime3,
  prodTime2,
  prodTime1,
  japanFlag,
  franceFlag,
  usFlag,
  ukFlag,
  germanyFlag,
  swedenFlag,
  nanoProd,
  microProd,
  smallProd,
  mediumProd,
  largeProd,
  XLargeProd,
  XXLargeProd,
  XXXLargeProd,
  XXXXLargeProd,
  XXXXXLargeProd,
  devices,
  viewportIcon,
  accountIcon,
  viewportOne,
  viewportTwo,
  viewportThree,
  viewportFour,
  viewportFive,
  viewportSix,
  personalExplorer,
  groupExplorer,
  productExplorer,
  Afghanistan,
  Albania,
  Algeria,
  Angola,
  Argentina,
  Australia,
  Austria,
  Azerbaijan,
  Bahrain,
  Bangladesh,
  Belarus,
  Belgium,
  Bhutan,
  Brazil,
  Bulgaria,
  Cambodia,
  Canada,
  Chad,
  Chile,
  Colombia,
  Comoros,
  CostaRica,
  Croatia,
  Cyprus,
  CzechRepublic,
  Denmark,
  Ecuador,
  Egypt,
  ElSalvador,
  Eritrea,
  Estonia,
  Ethiopia,
  Finland,
  France,
  Georgia,
  Germany,
  Greece,
  HongKong,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iraq,
  Ireland,
  Israel,
  Italy,
  Japan,
  Jordan,
  Kazakhstan,
  Kenya,
  KoreaRepublic,
  Kuwait,
  Latvia,
  LibyanArabJamahiriya,
  Lithuania,
  Luxembourg,
  Macedonia,
  Malaysia,
  Malta,
  Mauritania,
  Mauritius,
  Mexico,
  Moldova,
  Montenegro,
  Morocco,
  Mozambique,
  Myanmar,
  Nepal,
  Netherlands,
  NewZealand,
  Nigeria,
  Norway,
  Pakistan,
  Peru,
  Philippines,
  Poland,
  Portugal,
  PuertoRico,
  Qatar,
  Romania,
  Russia,
  Rwanda,
  SaudiArabia,
  Senegal,
  Serbia,
  Singapore,
  Slovakia,
  Slovenia,
  Somalia,
  SouthAfrica,
  SouthSudan,
  Spain,
  SriLanka,
  Sudan,
  Sweden,
  Switzerland,
  SyrianArabRepublic,
  Taiwan,
  Tajikistan,
  Thailand,
  Togo,
  Tunisia,
  Turkey,
  Turkmenistan,
  Ukraine,
  UnitedArabEmirates,
  UnitedKingdom,
  UnitedStates,
  Uzbekistan,
  Venezuela,
  Vietnam,
  Yemen,
  infoBipLogo,
  twilioLogo,
  theSmsWorksLogo,
  messageIcon
} from './../../../assets/images'

const imageMap: { [key: string]: string } = {
  vm: vmLogo,
  windows: windowsLogo,
  windowsAWS: windowsAWSLogo,
  windowsaws: windowsAWSLogo,
  windowsAZURE: windowsAZURELogo,
  windowsazure: windowsAZURELogo,
  'Microsoft Windows': windowsLogo,
  'Microsoft Windows AWS': windowsAWSLogo,
  'Microsoft Windows Azure': windowsAZURELogo,
  'Windows OSINT': windowsOsintLogo,
  'Mandiant': mandiantFlareLogo,
  'kali': kaliLogo,
  'SIFT Workstation': siftLogo,
  androidLogo: androidLogo,
  androidaws: androidaws,
  androidazure: androidazure,
  'android': androidLogo,
  'pixel-7-pro': androidLogo,
  ubuntu: ubuntuLogo,
  ubuntuAWS: ubuntuAWSLogo,
  ubuntuaws: ubuntuAWSLogo,
  ubuntuAZURE: ubuntuAZURELogo,
  ubuntuazure: ubuntuAZURELogo,
  kaliLogo: kaliLogo,
  kaliaws: kaliLogo,
  parrotLogo: parrotLogo,
  parrotaws: parrotLogo,
  reactOSLogo: reactOSLogo,
  reactOSaws: reactOSLogo,
  blackArchLogo: blackArchLogo,
  blackArchaws: blackArchLogo,
  siftLogo: siftLogo,
  siftaws: siftLogo,
  'InfoBip': infoBipLogo,
  'provider-001': infoBipLogo,
  'provider-002': twilioLogo,
  'provider-003': theSmsWorksLogo,
  'Twilio': twilioLogo,
  'The SMS Works': theSmsWorksLogo,
  'message': messageIcon,
  prodTime24: prodTime24,
  prodTime10: prodTime10,
  prodTime9: prodTime9,
  prodTime8: prodTime8,
  prodTime7: prodTime7,
  prodTime6: prodTime6,
  prodTime5: prodTime5,
  prodTime4: prodTime4,
  prodTime3: prodTime3,
  prodTime2: prodTime2,
  prodTime1: prodTime1,
  japanFlag: japanFlag,
  franceFlag: franceFlag,
  usFlag: usFlag,
  ukFlag: ukFlag,
  germanyFlag: germanyFlag,
  swedenFlag: swedenFlag,
  nanoProd: nanoProd,
  microProd: microProd,
  smallProd: smallProd,
  mediumProd: mediumProd,
  largeProd: largeProd,
  XLargeProd: XLargeProd,
  XXLargeProd: XXLargeProd,
  XXXLargeProd: XXXLargeProd,
  XXXXLargeProd: XXXXLargeProd,
  XXXXXLargeProd: XXXXXLargeProd,
  viewportIcon: viewportIcon,
  personalExplorer:personalExplorer,
  groupExplorer: groupExplorer,
  productExplorer: productExplorer,
  devices: devices,
  accountIcon: accountIcon,
  viewportOne: viewportOne,
  viewportTwo: viewportTwo,
  viewportThree: viewportThree,
  viewportFour: viewportFour,
  viewportFive: viewportFive,
  viewportSix: viewportSix,
  rocketchatLogo: rocketchatLogo,
  jitsi: jitsiLogo,
  jitsiaws: jitsiLogo,
  mandiant: mandiantFlareLogo,
  mandiantaws: mandiantFlareLogo,
  selkLogo: selkLogo,
  selaws: selkLogo,
  arkimeLogo: arkimeLogo,
  arkimeaws: arkimeLogo,
  'Afghanistan': Afghanistan,
  'Albania': Albania,
  'Algeria': Algeria,
  'Angola': Angola,
  'Argentina': Argentina,
  'Australia': Australia,
  'Austria': Austria,
  'Azerbaijan': Azerbaijan,
  'Bahrain': Bahrain,
  'Bangladesh': Bangladesh,
  'Belarus': Belarus,
  'Belgium': Belgium,
  'Bhutan': Bhutan,
  'Brazil': Brazil,
  'Bulgaria': Bulgaria,
  'Cambodia': Cambodia,
  'Canada': Canada,
  'Chad': Chad,
  'Chile': Chile,
  'Colombia': Colombia,
  'Comoros': Comoros,
  'Costa Rica': CostaRica,
  'Croatia': Croatia,
  'Cyprus': Cyprus,
  'Czech Republic': CzechRepublic,
  'Denmark': Denmark,
  'Ecuador': Ecuador,
  'Egypt': Egypt,
  'El Salvador': ElSalvador,
  'Eritrea': Eritrea,
  'Estonia': Estonia,
  'Ethiopia': Ethiopia,
  'Finland': Finland,
  'France': France,
  'Georgia': Georgia,
  'Germany': Germany,
  'Greece': Greece,
  'Hong Kong': HongKong,
  'Hungary': Hungary,
  'Iceland': Iceland,
  'India': India,
  'Indonesia': Indonesia,
  'Iraq': Iraq,
  'Ireland': Ireland,
  'Israel': Israel,
  'Italy': Italy,
  'Japan': Japan,
  'Jordan': Jordan,
  'Kazakhstan': Kazakhstan,
  'Kenya': Kenya,
  'Korea Republic': KoreaRepublic,
  'Kuwait': Kuwait,
  'Latvia': Latvia,
  'Libyan Arab Jamahiriya': LibyanArabJamahiriya,
  'Lithuania': Lithuania,
  'Luxembourg': Luxembourg,
  'Macedonia': Macedonia,
  'Malaysia': Malaysia,
  'Malta': Malta,
  'Mauritania': Mauritania,
  'Mauritius': Mauritius,
  'Mexico': Mexico,
  'Moldova': Moldova,
  'Montenegro': Montenegro,
  'Morocco': Morocco,
  'Mozambique': Mozambique,
  'Myanmar': Myanmar,
  'Nepal': Nepal,
  'Netherlands': Netherlands,
  'New Zealand': NewZealand,
  'Nigeria': Nigeria,
  'Norway': Norway,
  'Pakistan': Pakistan,
  'Peru': Peru,
  'Philippines': Philippines,
  'Poland': Poland,
  'Portugal': Portugal,
  'Puerto Rico': PuertoRico,
  'Qatar': Qatar,
  'Romania': Romania,
  'Russia': Russia,
  'Rwanda': Rwanda,
  'Saudi Arabia': SaudiArabia,
  'Senegal': Senegal,
  'Serbia': Serbia,
  'Singapore': Singapore,
  'Slovakia': Slovakia,
  'Slovenia': Slovenia,
  'Somalia': Somalia,
  'South Africa': SouthAfrica,
  'South Sudan': SouthSudan,
  'Spain': Spain,
  'Sri Lanka': SriLanka,
  'Sudan': Sudan,
  'Sweden': Sweden,
  'Switzerland': Switzerland,
  'Syrian Arab Republic': SyrianArabRepublic,
  'Taiwan': Taiwan,
  'Tajikistan': Tajikistan,
  'Thailand': Thailand,
  'Togo': Togo,
  'Tunisia': Tunisia,
  'Turkey': Turkey,
  'Turkmenistan': Turkmenistan,
  'Ukraine': Ukraine,
  'United Arab Emirates': UnitedArabEmirates,
  'United Kingdom': UnitedKingdom,
  'United States': UnitedStates,
  'Uzbekistan': Uzbekistan,
  'Venezuela': Venezuela,
  'Vietnam': Vietnam,
  'Yemen': Yemen,
};

const colourMap: { [key: string]: string } = {
  RS: 'color-RS',
  PT: 'color-PT',
  MA: 'color-MA',
  VC: 'color-VC',
  MS: 'color-MS',
  NF: 'color-NF',
  DA: 'color-DA',
  WI: 'color-WI',
  SH: 'color-SH',
  MD: 'color-MD',
};

const subSettings: types.IntSubSetting[] = [
  {
    id: 1,
    shortTitle: 'VPS',
    image: 'viewportIcon',
    title: 'Viewport Settings',
    description: 'Modify viewport settings for remote desktop access',
  },
  {
    id: 2,
    shortTitle: 'PS',
    image: 'devices',
    title: 'Product Settings',
    description: 'Modify product settings for your account',
  },
  {
    id: 2,
    shortTitle: 'AS',
    image: 'accountIcon',
    title: 'Account Settings',
    description: 'Modify your account settings',
  },
];


const subExplorer: types.IntSubSetting[] = [
  {
    id: 1,
    shortTitle: 'PEREXP',
    image: 'personalExplorer',
    title: 'Cloud Explorer',
    description: 'Cloud data storage for you and your information',
  },
  {
    id: 2,
    shortTitle: 'PRODEXP',
    image: 'productExplorer',
    title: 'Product Explorer',
    description: 'Data explorer for information relevant to your products',
  },
  {
    id: 3,
    shortTitle: 'PRODEXP',
    image: 'groupExplorer',
    title: 'Group Explorer',
    description: 'Group explorer for storing information you wish to share with others',
  },
];


let selectedProduct: types.IntProduct = {
  id: 1,
  shortTitle: 'RS',
  image: 'prodRS',
  header: 'Research',
  title: 'Opensource Research Desktop',
  type: 'vm',
  description: 'Investigative Desktop',
  version: '0.1.3.7',
  published: '12-08-2023',
  tooltip: { id: 1, image: '', title: '', description: ''},
  baseFee: 0.0416,
  defaultOS: 1,
  defaultLoc: 1,
  defaultDuration: 1,
  defaultCloud: 1,
  defaultSize: 1,
  metadataOne: '',
  osOptions: [
    { id: 1, selected: true, metadata: '', type: 'OS', image: 'windowsLogo', title: 'Microsoft Windows', description: 'Windows Desktop', fee: 0.0098, tooltip: { id: 1, image: 'WI', title: 'Windows', description: 'Microsoft Windows desktop'} },
    { id: 2, selected: false,metadata: '', type: 'OS', image: 'kaliLogo', title: 'Kali', description: 'Kali Desktop', fee: 0.0052, tooltip: { id: 1, image: 'KA', title: 'Kali', description: 'Kali Desktop'} },
    { id: 3, selected: false,metadata: '', type: 'OS', image: 'ubuntuLogo', title: 'Ubuntu', description: 'Ubuntu Desktop', fee: 0.0052, tooltip: { id: 1, image: 'UB', title: 'Ubuntu', description: 'Ubuntu Desktop'} },
    { id: 4, selected: false,metadata: '', type: 'OS', image: 'parrotLogo', title: 'ParrotOS', description: 'Privacy Desktop', fee: 0.0052, tooltip: { id: 1, image: 'PA', title: 'ParrotOS', description: 'ParrotOS Desktop'} },
    { id: 5, selected: false,metadata: '', type: 'OS', image: 'reactOSLogo', title: 'ReactOS', description: 'ReactOS Desktop', fee: 0.0052, tooltip: { id: 1, image: 'RA', title: 'ReactOS', description: 'ReactOS Desktop'} },
    { id: 6, selected: false,metadata: '', type: 'OS', image: 'blackArchLogo', title: 'BlackArch Linux', description: 'BlackArch Desktop', fee: 0.0052, tooltip: { id: 1, image: 'BA', title: 'BlackArch', description: 'BlackArch Linux Desktop'} },
    { id: 7, selected: false,metadata: '', type: 'OS', image: 'siftLogo', title: 'SIFT Workstation', description: 'SIFT Workstation Desktop', fee: 0.0052, tooltip: { id: 1, image: 'SW', title: 'SIFT', description: 'SIFT Workstation Desktop'} },
  ],
  locOptions: [
    {id: 1, selected: true, metadata: '', type: 'LOC', image: 'ukFlag', title: 'United Kingdom', description: 'Deployed in London', fee: 0.005, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 2, selected: false,metadata: '', type: 'LOC', image: 'japanFlag', title: 'Japan', description: 'Deployed in Toyko', fee: 0.005, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 3, selected: false,metadata: '', type: 'LOC', image: 'franceFlag', title: 'France', description: 'Deployed in Paris', fee: 0.005, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 4, selected: false,metadata: '', type: 'LOC', image: 'germanyFlag', title: 'Germany', description: 'Deployed in Frankfurt', fee: 0.005, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 5, selected: false,metadata: '', type: 'LOC', image: 'swedenFlag', title: 'Sweden', description: 'Deployed in Stockholm', fee: 0.005, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 6, selected: false,metadata: '', type: 'LOC', image: 'usFlag', title: 'United States', description: 'Deployed in Ohio', fee: 0.0, tooltip: { id: 1, image: '', title: '', description: ''}},
  ],
  choiceOptionsC: [
    {id: 1, selected: true, metadata: '', type: 'TIME', image: 'prodTime1', title: 'One Hour', description: '1 Hour consecutive use', fee: 1, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 2, selected: false,metadata: '', type: 'TIME', image: 'prodTime2', title: 'Two Hours', description: '2 Hours consecutive use', fee: 2, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 3, selected: false,metadata: '', type: 'TIME', image: 'prodTime3', title: 'Three Hours', description: '3 Hours consecutive use', fee: 3, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 4, selected: false,metadata: '', type: 'TIME', image: 'prodTime4', title: 'Four Hours', description: '4 Hours consecutive use', fee: 4, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 5, selected: false,metadata: '', type: 'TIME', image: 'prodTime5', title: 'Five Hours', description: '5 Hours consecutive use', fee: 5, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 10,selected: false,metadata: '', type: 'TIME',  image: 'prodTime10', title: 'Ten Hours', description: '10 Hours consecutive use', fee: 10, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 24, selected: false,metadata: '', type: 'TIME', image: 'prodTime24', title: '24 Hours', description: '24 Hours consecutive use', fee: 24, tooltip: { id: 1, image: '', title: '', description: ''}},
  ],
  cloudOptions: [
    {id: 1, selected: true,metadata: '', type: 'CLOUD', image: 'windows', title: 'Microsoft Windows', description: 'Windows Desktop', fee: 0.3, tooltip: { id: 1, image: '', title: '', description: ''}}
  ],
  sizeOptions: [
    {id: 1, selected: true,metadata: '', type: 'SIZE', image: 'nanoProd', title: 'Nano Instance', description: '2 CPU, 0.5GB RAM', fee: 1, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 2, selected: false,metadata: '', type: 'SIZE', image: 'microProd', title: 'Micro Instance', description: '2 CPU, 1GB RAM', fee: 2, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 3, selected: false,metadata: '', type: 'SIZE', image: 'smallProd', title: 'Small Instance', description: '2 CPU, 2GB RAM', fee: 4, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 4, selected: false,metadata: '', type: 'SIZE', image: 'mediumProd', title: 'Medium Instance', description: '2 CPU 4GB RAM', fee: 7, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 5, selected: false,metadata: '', type: 'SIZE', image: 'largeProd', title: 'Large Instance', description: '2 CPU, 8GB RAM', fee: 13, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 6, selected: false,metadata: '', type: 'SIZE', image: 'extraLargeProd', title: 'Extra Large', description: '4 CPU, 16GB RAM', fee: 25, tooltip: { id: 1, image: '', title: '', description: ''}},
    {id: 7, selected: false,metadata: '', type: 'SIZE', image: 'extraExtraLargeProd', title: 'Extra Extra Large', description: '8 CPU, 32GB RAM', fee: 50, tooltip: { id: 1, image: '', title: '', description: ''}},
  ],
  storageOptions: [
    {id: 1, selected: true,metadata: '', type: 'STORAGE', image: '', title: '40GB Storage', description: '40GB Volume', fee: 0.004383562, tooltip: { id: 1, image: '', title: '', description: ''}},
  ]
};

let products: types.IntOption[] = [
  { id: 1, selected: true,metadata: '', type: 'PROD', image: 'RS', title: 'Opensource Research Desktop', description: 'Investigative Desktop', fee: 0.3, tooltip: { id: 1, image: 'RS', title: 'Opensource Research Desktop', description: 'Gather information securely and anonymously using our specially designed investigative desktop. Loaded with all the tools to help you investigate your subject of interest'},},
  { id: 2, selected: true,metadata: '', type: 'PROD', image: 'PT', title: 'Penetration Test', description: 'Pen test a system, application, or network', fee: 0.2, tooltip: { id: 2, image: 'PT', title: 'Penetration Test', description: ''} },
  { id: 3, selected: true,metadata: '', type: 'PROD', image: 'MA', title: 'Malware Analysis', description: 'Analyse, Disassemble, Debug, Decompile', fee: 0.2, tooltip: { id: 3, image: 'MA', title: 'Malware Analysis', description: ''} },
  { id: 4, selected: true,metadata: '', type: 'PROD', image: 'NF', title: 'Network Forensics', description: 'Gather, Process, Visualise, Assess', fee: 0.2, tooltip: { id: 3, image: 'NA', title: 'Network Forensics', description: ''} },
  { id: 5, selected: true,metadata: '', type: 'PROD', image: 'DA', title: 'Data Analysis', description: 'Ingest, Normalise, Analyse, Report', fee: 0.2, tooltip: { id: 3, image: 'DA', title: 'Data Analysis', description: ''} },
  { id: 6, selected: true,metadata: '', type: 'PROD', image: 'VC', title: 'Video Conference', description: 'Video Conferencing Solutions for Security Professionals', fee: 0.2, tooltip: { id: 4, image: 'VC', title: 'Video Conference', description: ''} },
  { id: 7, selected: true,metadata: '', type: 'PROD', image: 'MS', title: 'Messaging Service', description: 'Communicate, share files and chat', fee: 0.2, tooltip: { id: 5, image: 'MS', title: 'Messaging Service', description: ''} },
  { id: 8, selected: true,metadata: '', type: 'PROD', image: 'SH', title: 'Command Line', description: 'Quck access to a headless command line', fee: 0.2, tooltip: { id: 5, image: 'MS', title: 'Messaging Service', description: ''} },
  { id: 9, selected: true,metadata: '', type: 'PROD', image: 'DE', title: 'Desktop Service', description: 'General purpose desktop', fee: 0.2, tooltip: { id: 5, image: 'MS', title: 'Messaging Service', description: ''} },
];

const suggestedSearch = [
  {
    id: 1,
    shortTitle: 'MA',
    image: 'MA',
    title: 'Malware Analysis',
    description: 'Analyse, Disassemble, Debug, Decompile',
    title_terms: 'Analyse file malware',
    description_terms: '',
    terms: 'malware reverse engineering static analysis dynamic analysis behavioral analysis automated analysis signature-based detection heuristic detection sandboxing debugging disassembler decompiler virtual machine rootkit Trojan worm botnet ransomware spyware adware exploit zero-day vulnerability antivirus antimalware firewall intrusion detection system intrusion prevention system ids ips endpoint security threat intelligence IOC indicators of compromise YARA Cuckoo Sandbox VirusTotal IDA Pro Ghidra Wireshark Metasploit Volatility REMnux OllyDbg x64dbg Radare2 cyber threat intelligence malware samples malware repository threat hunting',
  },
  {
    id: 2,
    image: 'PT',
    shortTitle: 'PT',
    title: 'Penetration Test',
    description: 'Pen test a system, application, or network',
    title_terms: 'Pentest pen test pen-test',
    description_terms: '',
    terms: 'owasp penetration testing web application security cross-site scripting xss sql injection cross-site request forgery csrf security misconfiguration sensitive data exposure broken authentication insecure direct object references idor unvalidated redirects and forwards xml external entity attacks xxe server side request forgery ssrf command injection path traversal http verb tampering http response splitting session hijacking web application firewall waf html injection directory listing local file inclusion lfi remote file inclusion rfi cookie manipulation parameter tampering clickjacking burp suite zap postman metasploit kali linux webgoat dvwa bWAPP nmap sqlmap wireshark nessus nikto john ripper hashcat',
  },
  {
    id: 3,
    image: 'RS',
    shortTitle: 'RS',
    title: 'Opensource Research Desktop',
    description: 'Investigative Desktop',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: 'open source intelligence OSINT investigate research data mining web scraping social media intelligence SOCMINT human intelligence HUMINT geospatial intelligence GEOINT signal intelligence SIGINT cyber threat intelligence CTI threat hunting deep web dark web WHOIS DNS lookup IP address tracking Google dorking Shodan reverse image search metadata analysis network mapping threat intelligence platform TIP link analysis Maltego theHarvester Recon-ng FOCA SpiderFoot OSINT framework OSRFramework TweetDeck advanced search operators RSS feeds public records open databases online forums geo-location data GPS coordinates EXIF data cybersecurity news and alerts CERT alerts and bulletins'
  },
  {
    id: 4,
    image: 'NF',
    shortTitle: 'NF',
    title: 'Network Forensics',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: 'Packet Capture PCAP Wireshark NetFlow TCPDump Packet Sniffing Network Traffic Analysis Intrusion Detection System IDS Intrusion Prevention System IPS Network Protocol Analysis Deep Packet Inspection DPI Network Tap Network Miner Session Data Timestamp Flags Network Anomaly Detection Firewall Logs Proxy Logs Network Topology Discovery Payload Analysis OS Fingerprinting Port Mirroring SPAN Bro Zeek Suricata Traffic Reconstruction VoIP Traffic Analysis Man-in-the-Middle Attack Detection'
  },
  {
    id: 5,
    image: 'DA',
    shortTitle: 'DA',
    title: 'Data Analysis',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: '--'
  },
  {
    id: 6,
    image: 'DE',
    shortTitle: 'DE',
    title: 'Desktop ---',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: '--'
  },
  {
    id: 7,
    image: 'DE',
    shortTitle: 'DE',
    title: 'Desktop ---',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Desktop',
    description_terms: '',
    terms: '--'
  },
  {
    id: 8,
    image: 'VC',
    shortTitle: 'VC',
    title: 'Viedo ---',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Video',
    description_terms: '',
    terms: '--'
  },
  {
    id: 9,
    image: 'ME',
    shortTitle: 'ME',
    title: 'Messaging ---',
    description: 'Gather, Process, Visualise, Assess',
    title_terms: 'Messaging',
    description_terms: '',
    terms: '--'
  },
];

const suggestedSearchBackup = [
  {
    id: 1,
    image: 'none',
    title: 'Store data and tools securely',
    description: '',
    title_terms: 'Store data tools securely',
    description_terms: '',
    terms: 'Store data tools secure'
  },
  {
    id: 2,
    image: 'none',
    title: 'Pentest a web application',
    description: '',
    title_terms: 'Pentest web application',
    description_terms: '',
    terms: 'owasp penetration testing web application security cross-site scripting xss sql injection cross-site request forgery csrf security misconfiguration sensitive data exposure broken authentication insecure direct object references idor unvalidated redirects and forwards xml external entity attacks xxe server side request forgery ssrf command injection path traversal http verb tampering http response splitting session hijacking web application firewall waf html injection directory listing local file inclusion lfi remote file inclusion rfi cookie manipulation parameter tampering clickjacking burp suite zap postman metasploit kali linux webgoat dvwa bWAPP nmap sqlmap wireshark nessus nikto john ripper hashcat',
  },
  {
    id: 3,
    image: 'none',
    title: 'Share report and analysis with client',
    description: '',
    title_terms: 'Share report analysis client',
    description_terms: '',
    terms: 'document secure file transfer email storage authentication protected documents signature document exchange portal data VPN private collaboration messaging confidential non-disclosure NDA privacy GDPR General Data Protection Regulation CCPA California Consumer Privacy Act'
  },
  {
    id: 4,
    image: 'MA',
    title: 'Malware Analysis',
    description: 'Analyse, Disassemble, Debug, Decompile',
    title_terms: 'Analyse file malware',
    description_terms: '',
    terms: 'malware reverse engineering static analysis dynamic analysis behavioral analysis automated analysis signature-based detection heuristic detection sandboxing debugging disassembler decompiler virtual machine rootkit Trojan worm botnet ransomware spyware adware exploit zero-day vulnerability antivirus antimalware firewall intrusion detection system intrusion prevention system ids ips endpoint security threat intelligence IOC indicators of compromise YARA Cuckoo Sandbox VirusTotal IDA Pro Ghidra Wireshark Metasploit Volatility REMnux OllyDbg x64dbg Radare2 cyber threat intelligence malware samples malware repository threat hunting',
  },
  {
    id: 5,
    image: 'none',
    title: 'Research a subject of interest',
    description: '',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: 'open source intelligence OSINT data mining web scraping social media intelligence SOCMINT human intelligence HUMINT geospatial intelligence GEOINT signal intelligence SIGINT cyber threat intelligence CTI threat hunting deep web dark web WHOIS DNS lookup IP address tracking Google dorking Shodan reverse image search metadata analysis network mapping threat intelligence platform TIP link analysis Maltego theHarvester Recon-ng FOCA SpiderFoot OSINT framework OSRFramework TweetDeck advanced search operators RSS feeds public records open databases online forums geo-location data GPS coordinates EXIF data cybersecurity news and alerts CERT alerts and bulletins'
  },
  {
    id: 6,
    image: 'none',
    title: 'Conduct a vulnerability assessment',
    description: '',
    title_terms: 'Conduct vulnerability assessment',
    description_terms: '',
    terms: 'vulnerability assessment vulnerability scanning CVE Common Vulnerabilities and Exposures CVSS Common Vulnerability Scoring System OWASP Open Web Application Security Project Nessus OpenVAS Nexpose Qualys Burp Suite Metasploit penetration testing security auditing false positive false negative risk assessment patch management network security web application security host-based security firewall intrusion detection system IDS intrusion prevention system IPS endpoint protection software antivirus software zero-day vulnerabilities threat modeling attack surface encryption two-factor authentication multi-factor authentication secure coding practices source code analysis fuzz testing social engineering phishing spear phishing patching vulnerabilities software updates secure configuration threat intelligence'
  },
  {
    id: 7,
    image: 'none',
    title: 'Manage and respond to an incident',
    description: '',
    title_terms: 'Manage respond incident',
    description_terms: '',
    terms: 'incident response incident management IR incident response team IRT Computer Emergency Response Team CERT cybersecurity incident network breach data breach phishing malware ransomware DDoS Distributed Denial of Service attack intrusion detection system IDS intrusion prevention system IPS firewall log analysis SIEM Security Information and Event Management threat intelligence IOC Indicators of Compromise forensics digital forensics incident response plan IRP incident response lifecycle preparation identification containment eradication recovery lessons learned post-incident analysis root cause analysis threat hunting incident prioritization triage incident reporting communication plan disaster recovery business continuity plan BCP data backup encryption two-factor authentication multi-factor authentication VPN virtual private network endpoint protection'
  },
  {
    id: 8,
    image: 'none',
    title: 'Reverse engineer an executable',
    description: '',
    title_terms: 'Reverse engineer executable',
    description_terms: '',
    terms: 'reverse engineering disassembler decompiler debugger static analysis dynamic analysis control flow graph call stack heap code injection breakpoints memory dump machine code assembly language IDA Pro Ghidra Radare2 OllyDbg x64dbg WinDbg Hopper Binary Ninja PE Portable Executable ELF Executable and Linkable Format Mach-O executable formats packers unpackers obfuscation code protection antivirus evasion software cracking reverse engineering tools malware analysis rootkit analysis exploit development reverse code engineering patching binaries software license bypassing'
  },

];

const mainSearch = [
  {
    id: 1,
    image: 'none',
    title: 'Store data and tools securely',
    description: '',
    title_terms: 'Store data tools securely',
    description_terms: '',
    terms: 'Store data tools secure'
  },
  {
    id: 2,
    image: 'none',
    title: 'Pentest a web application',
    description: '',
    title_terms: 'Pentest web application',
    description_terms: '',
    terms: 'owasp penetration testing web application security cross-site scripting xss sql injection cross-site request forgery csrf security misconfiguration sensitive data exposure broken authentication insecure direct object references idor unvalidated redirects forwards xml external entity attacks xxe server side request forgery ssrf command injection path traversal http verb tampering http response splitting session hijacking web application firewall waf html injection directory listing local file inclusion lfi remote file inclusion rfi cookie manipulation parameter tampering clickjacking burp suite zap postman metasploit kali linux webgoat dvwa bWAPP nmap sqlmap wireshark nessus nikto john ripper hashcat',
  },
  {
    id: 3,
    image: 'none',
    title: 'Share report and analysis with client',
    description: '',
    title_terms: 'Share report analysis client',
    description_terms: '',
    terms: 'document secure file transfer email storage authentication protected documents signature document exchange portal data VPN private collaboration messaging confidential non-disclosure NDA privacy GDPR General Data Protection Regulation CCPA California Consumer Privacy Act'
  },
  {
    id: 4,
    image: 'none',
    title: 'Analyse a file for malware',
    description: '',
    title_terms: 'Analyse file malware',
    description_terms: '',
    terms: 'malware reverse engineering static analysis dynamic analysis behavioral analysis automated analysis signature-based detection heuristic detection sandboxing debugging disassembler decompiler virtual machine rootkit Trojan worm botnet ransomware spyware adware exploit zero-day vulnerability antivirus antimalware firewall intrusion detection system intrusion prevention system ids ips endpoint security threat intelligence IOC indicators of compromise YARA Cuckoo Sandbox VirusTotal IDA Pro Ghidra Wireshark Metasploit Volatility REMnux OllyDbg x64dbg Radare2 cyber threat intelligence malware samples malware repository threat hunting',
  },
  {
    id: 5,
    image: 'none',
    title: 'Research a subject of interest',
    description: '',
    title_terms: 'Research subject interest',
    description_terms: '',
    terms: 'open source intelligence OSINT data mining web scraping social media intelligence SOCMINT human intelligence HUMINT geospatial intelligence GEOINT signal intelligence SIGINT cyber threat intelligence CTI threat hunting deep web dark web WHOIS DNS lookup IP address tracking Google dorking Shodan reverse image search metadata analysis network mapping threat intelligence platform TIP link analysis Maltego theHarvester Recon-ng FOCA SpiderFoot OSINT framework OSRFramework TweetDeck advanced search operators RSS feeds public records open databases online forums geo-location data GPS coordinates EXIF data cybersecurity news alerts CERT alerts bulletins'
  },
  {
    id: 6,
    image: 'none',
    title: 'Conduct a vulnerability assessment',
    description: '',
    title_terms: 'Conduct vulnerability assessment',
    description_terms: '',
    terms: 'vulnerability assessment vulnerability scanning CVE Common Vulnerabilities Exposures CVSS Common Vulnerability Scoring System OWASP Open Web Application Security Project Nessus OpenVAS Nexpose Qualys Burp Suite Metasploit penetration testing security auditing false positive false negative risk assessment patch management network security web application security host-based security firewall intrusion detection system IDS intrusion prevention system IPS endpoint protection software antivirus software zero-day vulnerabilities threat modeling attack surface encryption two-factor authentication multi-factor authentication secure coding practices source code analysis fuzz testing social engineering phishing spear phishing patching vulnerabilities software updates secure configuration threat intelligence'
  },
  {
    id: 7,
    image: 'none',
    title: 'Manage and respond to an incident',
    description: '',
    title_terms: 'Manage respond incident',
    description_terms: '',
    terms: 'incident response incident management IR incident response team IRT Computer Emergency Response Team CERT cybersecurity incident network breach data breach phishing malware ransomware DDoS Distributed Denial of Service attack intrusion detection system IDS intrusion prevention system IPS firewall log analysis SIEM Security Information Event Management threat intelligence IOC Indicators of Compromise forensics digital forensics incident response plan IRP incident response lifecycle preparation identification containment eradication recovery lessons learned post-incident analysis root cause analysis threat hunting incident prioritization triage incident reporting communication plan disaster recovery business continuity plan BCP data backup encryption two-factor authentication multi-factor authentication VPN virtual private network endpoint protection'
  },
  {
    id: 8,
    image: 'none',
    title: 'Reverse engineer an executable',
    description: '',
    title_terms: 'Reverse engineer executable',
    description_terms: '',
    terms: 'reverse engineering disassembler decompiler debugger static analysis dynamic analysis control flow graph call stack heap code injection breakpoints memory dump machine code assembly language IDA Pro Ghidra Radare2 OllyDbg x64dbg WinDbg Hopper Binary Ninja PE Portable Executable ELF Executable Linkable Format Mach-O executable formats packers unpackers obfuscation code protection antivirus evasion software cracking reverse engineering tools malware analysis rootkit analysis exploit development reverse code engineering patching binaries software license bypassing'
  },

];


const testPurchasedProducts: types.IntPurchasedProduct[] = [
  {
    id: selectedProduct.id,
    shortTitle: selectedProduct.shortTitle,
    image: selectedProduct.image,
    header: selectedProduct.header,
    title: selectedProduct.title,
    type: selectedProduct.type,
    description: selectedProduct.description,
    version: selectedProduct.version,
    published: selectedProduct.published,
    tooltip: selectedProduct.tooltip,
    baseFee: selectedProduct.baseFee,
    defaultOS: selectedProduct.defaultOS,
    defaultLoc: selectedProduct.defaultLoc,
    defaultDuration: selectedProduct.defaultDuration,
    defaultCloud: selectedProduct.defaultCloud,
    defaultSize: selectedProduct.defaultSize,
    osOption: selectedProduct.osOptions[0] ,
    locOption: selectedProduct.locOptions[0], 
    choiceOptionC: selectedProduct.choiceOptionsC[0], 
    cloudOption: selectedProduct.cloudOptions[0], 
    sizeOption: selectedProduct.sizeOptions[0],  
    storageOptions: selectedProduct.storageOptions[0], 
    statusOption: { id: 1, selected: true, metadata: '', type: 'STATUS', image: 'running', title: 'Running', description: 'Product is running', fee: 1, tooltip: { id: 1, image: '', title: '', description: ''} },
    metadataOne: selectedProduct.metadataOne,

  },
  {
    id: 2,
    shortTitle: 'PT',
    image: selectedProduct.image,
    header: 'Pentest',
    title: 'Product 2',
    type: 'vm',
    description: 'Pentest Instance',
    version: selectedProduct.version,
    published: selectedProduct.published,
    tooltip: selectedProduct.tooltip,
    baseFee: selectedProduct.baseFee,
    defaultOS: selectedProduct.defaultOS,
    defaultLoc: selectedProduct.defaultLoc,
    defaultDuration: selectedProduct.defaultDuration,
    defaultCloud: selectedProduct.defaultCloud,
    defaultSize: selectedProduct.defaultSize,
    osOption: selectedProduct.osOptions[1] ,
    locOption: selectedProduct.locOptions[1], 
    choiceOptionC: selectedProduct.choiceOptionsC[1], 
    cloudOption: selectedProduct.cloudOptions[1], 
    sizeOption: selectedProduct.sizeOptions[1],  
    storageOptions: selectedProduct.storageOptions[0], 
    statusOption: { id: 1, selected: false, metadata: '', type: 'STATUS', image: 'stopped', title: 'Stopped', description: 'Product is stopped', fee: 1, tooltip: { id: 1, image: '', title: '', description: ''} },
    metadataOne: selectedProduct.metadataOne,

  },
  {
    id: 3,
    shortTitle: 'SH',
    image: selectedProduct.image,
    header: 'Gaming',
    title: 'Gaming Box',
    type: 'vm',
    description: 'Metla Gear Solid',
    version: selectedProduct.version,
    published: selectedProduct.published,
    tooltip: selectedProduct.tooltip,
    baseFee: selectedProduct.baseFee,
    defaultOS: selectedProduct.defaultOS,
    defaultLoc: selectedProduct.defaultLoc,
    defaultDuration: selectedProduct.defaultDuration,
    defaultCloud: selectedProduct.defaultCloud,
    defaultSize: selectedProduct.defaultSize,
    osOption: selectedProduct.osOptions[2] ,
    locOption: selectedProduct.locOptions[2], 
    choiceOptionC: selectedProduct.choiceOptionsC[2], 
    cloudOption: selectedProduct.cloudOptions[2], 
    sizeOption: selectedProduct.sizeOptions[2],  
    storageOptions: selectedProduct.storageOptions[0], 
    statusOption: { id: 1, selected: true, metadata: '', type: 'STATUS', image: 'hibernated', title: 'Hibernated', description: 'Product is hibernated', fee: 1, tooltip: { id: 1, image: '', title: '', description: ''} },
    metadataOne: selectedProduct.metadataOne,

  },

]


export {  
  products,
    
  //
  testPurchasedProducts,
  mainSearch,
  suggestedSearchBackup,
  suggestedSearch,
  selectedProduct,
  subSettings,
  subExplorer,
  colourMap,
  imageMap,
};
