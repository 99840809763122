// import React from 'react';
// import ReactDOM from 'react-dom';

// import './i18n';

// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

import { createRoot } from 'react-dom/client';

import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as helpers from 'utilities/helpers';

// If you have an existing configContext for private config:
import { ConfigProvider } from 'utilities/variables/configContext';
import { configureAmplify } from 'utilities/services/amplifyCognito';

// New open config context
import { OpenConfigProvider } from 'utilities/variables/openConfigContext';

// The function to fetch open config
import { api, types } from 'utilities'; // <-- Adjust path as needed

let EXP_LAMBDA_API_URL = '';
let EXP_ENVIRONMENT = '';

async function initApp() {
  try {
    // 1) Fetch open configuration from your new endpoint
    //console.log('Fetching open configuration...');
    const domainName = window.location.hostname;
    const response = await api.getOpenConfiguration('https://'+domainName+'/api');
    // This typically returns { status, body, errorMessage }

    // 2) Check if successful and store the results
    let openConfig: types.IOpenConfig = {};
    if (response?.status === 'SUCCESS') {
      // If your body is already parsed as an object:
      openConfig = response.body;
    } else {
      //console.warn('Failed to fetch open config:', response?.errorMessage);
      // Optionally define fallback config if needed
    }

    // 3) Productionise if openConfig says we are in PRODUCTION
    if ((openConfig as any).ENVIRONMENT === 'PRODUCTION') {
      // eslint-disable-next-line no-console
      console.log = () => {};
    }

    helpers.logToOutput('!! Open config:', openConfig);
    
    // 4) Configure Amplify:

    EXP_LAMBDA_API_URL = openConfig.LAMBDA_API_URL;
    EXP_ENVIRONMENT = openConfig.ENVIRONMENT;

    configureAmplify({
      COGNITO_USERPOOL_ID: openConfig.COGNITO_USERPOOL_ID,
      COGNITO_CLIENT_ID: openConfig.COGNITO_CLIENT_ID,
      AUTHENTICATION_FLOW: openConfig.AUTHENTICATION_FLOW
    });

    // 5) Now render the app
    const container = document.getElementById('root');
    if (!container) {
      throw new Error('Root container not found');
    }
    const root = createRoot(container);
    root.render(
      <OpenConfigProvider config={openConfig}>
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </OpenConfigProvider>
    );

    // 5) (Optional) Start performance measuring
    reportWebVitals();
  } catch (error) {
    //console.error('Error initialising app:', error);
  }
}

// Immediately invoke the async function
initApp();

export { EXP_LAMBDA_API_URL, EXP_ENVIRONMENT };