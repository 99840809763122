import { useEffect, useRef, useState } from 'react';
import useStateWithRef from 'react-usestateref'
import axios from 'axios'
import { Alert, Col, Popover, Row, Spinner } from 'react-bootstrap';

import { helpers, types, variables } from 'utilities';

import { useConfig } from 'utilities/variables/configContext';
import { botIcon, userIcon } from 'assets/images';
import { menus } from 'components';


export const ChatBotPanel: React.FC<types.IntChatBotPanel> = (props) => {

  const { config } = useConfig();
  const defaultMenuOptions: types.IntChatMenuData = {
    icon: '',
    label: '',
    data: {},
    position: { top: 0, left: 0 },
  };
  var [messages, setMessages, messageRef] = useStateWithRef<types.IntChatMessageDynamic[]>([]);
  const [userInput, setUserInput] = useState('');
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useStateWithRef(defaultMenuOptions);
  const [isContextMenuVisible, setContextMenuVisible] = useState(false);

  const urlObj = new URL(props.src);
  const pathSegments = urlObj.pathname.split('/').filter(Boolean);  // split by "/" and filter out empty strings
  const extractedID = pathSegments[pathSegments.length - 1];
  const CHATBOT_API_ENDPOINT = 'https://'+extractedID+'.vm.'+config.DOMAIN_URL+':4001/query';

  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the chat whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  //Set initial message
  useEffect(() => {
    const storedMessages = sessionStorage[extractedID];
    
    if (storedMessages && storedMessages[0] != null) {
      
      setMessages(JSON.parse(storedMessages).current);
    } else {
      
      const ts = new Date();
      const hours = String(ts.getHours()).padStart(2, '0');
      const minutes = String(ts.getMinutes()).padStart(2, '0');
      // For example, you could fetch this message from an API or base it on user data
      const initialBotMessage: types.IntChatMessageDynamic = { sender: 'bot', content: 'Hello! To use this service please put any PDF files you wish to analyse into the kai-input folder', time: `${hours}:${minutes}`};
      setMessages([initialBotMessage]);
      sessionStorage.setItem(extractedID, JSON.stringify(messageRef));
      
    }
  }, []); // An empty dependency array means this useEffect runs once when the component mounts

  useEffect(() => {
    // Add a click event listener to the window to handle clicks outside the context menu
    const handleWindowClick = () => {
      if (isContextMenuVisible) {
        setContextMenuVisible(false);
      }
    };

    // Add a scroll event listener to the window to handle scroll events
    const handleWindowScroll = () => {
      if (isContextMenuVisible) {
        setContextMenuVisible(false);
      }
    };

    // Attach the event listeners when the component mounts
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('scroll', handleWindowScroll);

    // Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [isContextMenuVisible]);

  const clearMessages = () => {
    setMessages([]);
    sessionStorage.setItem(extractedID, '[]');
  }
 
  const handleContextMenu = (
    label: string, 
    e: React.MouseEvent
  ) => {
    e.preventDefault(); // Prevent the default context menu from appearing
    
    
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const yPos = e.clientY - 85
    const xPos = (e.clientX - viewportWidth) + 120

    const options: types.IntChatMenuData = {
      icon: '',
      label: label,
      data: { event: e, deviceID: extractedID, clearMsgCallback: clearMessages },
      position: { top: yPos, left: xPos },
    };
    setMenuOptions(options);
    setContextMenuVisible(true);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;

    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    // Add user's message to chat
    //setMessages([...messages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }]);
    //setMessages(prevMessages => [...prevMessages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }]);

    setMessages(prevMessages => {
      // Checking if there are existing messages
      if (prevMessages && prevMessages.length > 0) {
        // There are existing messages, add the new one
        return [...prevMessages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }];
      } else {
        // No existing messages, start with the new one
        return [{ sender: 'user', content: userInput, time: `${hours}:${minutes}` }];
      }
    });

    sessionStorage.setItem(extractedID, JSON.stringify(messageRef));
    setMessageSent(true)

    try {
      const response = await axios.post(CHATBOT_API_ENDPOINT, { query: userInput }, { withCredentials: true });
      const botReply = response.data.response; // Assuming 'reply' is the key containing the bot's response

      const nowRes = new Date();
      const hoursRes = String(nowRes.getHours()).padStart(2, '0');
      const minutesRes = String(nowRes.getMinutes()).padStart(2, '0');

      // Add bot's reply to chat
      //setMessages([...messages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }, { sender: 'bot', content: botReply, time: `${hoursRes}:${minutesRes}` }]);
      setMessages(prevMessages => [...prevMessages, { sender: 'bot', content: botReply, time: `${hoursRes}:${minutesRes}` }]);

      setMessageSent(false)

      sessionStorage.setItem(extractedID, JSON.stringify(messageRef));
      
      
      setUserInput('');
      
    } catch (error) {
      helpers.logToOutput('Error sending message:', error);

      // Handle error accordingly (e.g., show a user-friendly message)
    }
   
  };


  //<div className="context-menu" style={{ top: menuOptions.position.top, left: menuOptions.position.left }}>
  helpers.logToOutput('CONFIGURATION!!! ALL : ', config);
  helpers.logToOutput('CONFIGURATION!!! KAI : ', config.KAI);

  return (
    <>
      <div className='chat-container k-minh-100pc m-0 p-0 k-bg-white b-rad-5 b-shadow-on' >
        {config && config.KAI ? (
          <>
            <div className='chat-messages txt-12' onContextMenu={(e) => handleContextMenu( 'null', e)}>
              {messages && messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index} className={`p-1 chat-message ${msg.sender}`}>
                    <Row  className={`m-0 pt-1 ps-1 pe-1 pb-0 chat-message-bg ${msg.sender} k-center-content-hor-right b-rad-t-5`}>
                      <Col className='k-mw-33 m-0 p-1'>
                        <Row className='p-0 m-0'>
                          <Col className='p-0 m-0'>
                            { msg.sender === 'user' ? (
                              <img 
                                src={userIcon}
                                alt={msg.sender}
                                className="sender-icon k-mh-20 "
                              />
                            ) : (
                              <img 
                                src={botIcon}
                                alt={msg.sender}
                                className="sender-icon k-mh-20 "
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className='p-0 m-0'>
                          <Col className='p-0 m-0 txt-8'>
                            {msg.time}
                          </Col>
                        </Row>
                      </Col>
                      <Col className='p-1'>
                        {msg.content}
                      </Col>
                    </Row>
                    {index != 0  && msg.sender != 'user' ? (
                      <Row className={`m-0 pt-0 ps-1 pe-1 pb-0 chat-message-bg ${msg.sender} k-center-content-hor-right b-rad-b-5`}>
                        <Col className='col-auto p-0 pb-1 d-flex justify-content-end align-items-end'>
                          {/*<MdContentCopy 
                          className="copy-icon" 
                          style={{ cursor: 'pointer'}}
                          onClick={() => handleCopy(msg.content)}
                        />*/}
                        </Col>
                      </Row>
                    ) : (
                      <Row className={`m-0 p-0 b-rad-b-5 k-h-5 chat-message-bg ${msg.sender}`}><Col className={'m-0 p-0'}></Col></Row>
                    )}
                  </div>
                ))) : (
                <>
                </>
              )}
              {/* Invisible div at the end of messages */}
              {messageSent === true  && ( 
                <>
                  <Row>
                    <Col className='col-12'>
                      <Spinner className='pe-2' animation="border" variant="secondary" size="sm"/>
                      <span className='ps-1'>Thinking ...</span>
                    </Col>
                  </Row>
                  
                </>
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className='chat-input txt-12'>
              <input
                value={userInput}
                onChange={e => setUserInput(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();  // Prevents default behavior of a newline in some cases
                    handleSendMessage();
                    setUserInput('');  // Clear the input box
                  }
                }}
                placeholder="Type a message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <>
            <Row className='p-1 m-0'>
              <Col className='p-1 m-0'>
                <Alert variant="warning" className='txt-12 p-1'>
                  <Row className='p-0 m-0'>
                    <Col className='col-2 p-1 m-0'>
                      <img src={botIcon} className="sender-icon k-mh-30"/>
                    </Col>
                    <Col className='p-1 m-0'>
                      A.I. chat has not been enabled for your platform <br/><br/>
                      Please contact your systems administrator to enable this function
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </Row>
          </>
        )
        }
      </div>
      {isContextMenuVisible && (
        <div className="context-menu k-b-rad-20 k-bg-trans" style={{ top: menuOptions.position.top, left: menuOptions.position.left }}>
          <menus.ChatMenuPopup menuData={menuOptions} isUserAuthed={props.isUserAuthed}/>
        </div>
      )}
    </>
    
  );
};




export const ProductWindowChatBotPanelWIP: React.FC<types.IntProductWindowChatBotWIP> = ({ messages, currentUserInput, handleSendUserMessage, handleUserInputChange}) => {

  const handleKeyDown = async(event: any) =>  {
    if (event.key === 'Enter' && ! event.shiftKey) {
      event.preventDefault();  // Prevents default behavior of a newline in some cases
      await handleSendUserMessage();
    }
  }
    
  return (
    
    <div className='chat-container k-w-350 k-minh-100pc m-0 p-0 k-bg-white b-rad-5 b-shadow-on' >
      <div className='chat-messages'>
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.sender}`}>
            <Row className='m-0 p-0'>
              <Col className='col-1 m-0 p-0'>
                <img 
                  src={msg.sender === 'bot' ? botIcon : userIcon} 
                  alt={msg.sender} 
                  className="sender-icon k-mh-20 "
                />
              </Col>
              <Col className='col-11 ps-1 p-0'>
                {msg.content}
              </Col>
            </Row>
          </div>
        ))}
      </div>
      
      <div className='chat-input '>
        <input
          value={currentUserInput}
          onChange={handleUserInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <button onClick={handleSendUserMessage}>Send</button>
      </div>
    </div>
    
  );
};

export const ProductWindowChatBotPanel: React.FC<types.IntProductWindowChatBot> = ({ isUserAuthed, productId}) => {
  const { config } = useConfig();
  const defaultMenuOptions: types.IntChatMenuData = {
    icon: '',
    label: '',
    data: {},
    position: { top: 0, left: 0 },
  };
  var [messages, setMessages, messageRef] = useStateWithRef<types.IntChatMessageDynamic[]>([]);
  const [userInput, setUserInput] = useState('');
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useStateWithRef(defaultMenuOptions);
  const [isContextMenuVisible, setContextMenuVisible] = useState(false);

  
  const CHATBOT_API_ENDPOINT = `https://${productId}.vm.${config.DOMAIN_URL}:4001/query`;

  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the chat whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  //Set initial message
  useEffect(() => {
    const storedMessages = sessionStorage[productId];
    
    if (storedMessages && storedMessages[0] != null) {
      
      setMessages(JSON.parse(storedMessages).current);
    } else {
      
      const ts = new Date();
      const hours = String(ts.getHours()).padStart(2, '0');
      const minutes = String(ts.getMinutes()).padStart(2, '0');
      // For example, you could fetch this message from an API or base it on user data
      const initialBotMessage: types.IntChatMessageDynamic = { sender: 'bot', content: 'Hello! To use this service please put any PDF files you wish to analyse into the kai-input folder', time: `${hours}:${minutes}`};
      setMessages([initialBotMessage]);
      sessionStorage.setItem(productId, JSON.stringify(messageRef));
      
    }
  }, []); // An empty dependency array means this useEffect runs once when the component mounts

  useEffect(() => {
    // Add a click event listener to the window to handle clicks outside the context menu
    const handleWindowClick = () => {
      if (isContextMenuVisible) {
        setContextMenuVisible(false);
      }
    };

    // Add a scroll event listener to the window to handle scroll events
    const handleWindowScroll = () => {
      if (isContextMenuVisible) {
        setContextMenuVisible(false);
      }
    };

    // Attach the event listeners when the component mounts
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('scroll', handleWindowScroll);

    // Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [isContextMenuVisible]);

  const clearMessages = () => {
    setMessages([]);
    sessionStorage.setItem(productId, '[]');
  }
 
  const handleContextMenu = (
    label: string, 
    e: React.MouseEvent
  ) => {
    e.preventDefault(); // Prevent the default context menu from appearing
    
    
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const yPos = e.clientY - 85
    const xPos = (e.clientX - viewportWidth) + 120

    const options: types.IntChatMenuData = {
      icon: '',
      label: label,
      data: { event: e, deviceID: productId, clearMsgCallback: clearMessages },
      position: { top: yPos, left: xPos },
    };
    setMenuOptions(options);
    setContextMenuVisible(true);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;

    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    // Add user's message to chat
    //setMessages([...messages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }]);
    //setMessages(prevMessages => [...prevMessages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }]);

    setMessages(prevMessages => {
      // Checking if there are existing messages
      if (prevMessages && prevMessages.length > 0) {
        // There are existing messages, add the new one
        return [...prevMessages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }];
      } else {
        // No existing messages, start with the new one
        return [{ sender: 'user', content: userInput, time: `${hours}:${minutes}` }];
      }
    });

    sessionStorage.setItem(productId, JSON.stringify(messageRef));
    setMessageSent(true)

    try {
      const response = await axios.post(CHATBOT_API_ENDPOINT, { query: userInput }, { withCredentials: true });
      const botReply = response.data.response; // Assuming 'reply' is the key containing the bot's response

      const nowRes = new Date();
      const hoursRes = String(nowRes.getHours()).padStart(2, '0');
      const minutesRes = String(nowRes.getMinutes()).padStart(2, '0');

      // Add bot's reply to chat
      //setMessages([...messages, { sender: 'user', content: userInput, time: `${hours}:${minutes}` }, { sender: 'bot', content: botReply, time: `${hoursRes}:${minutesRes}` }]);
      setMessages(prevMessages => [...prevMessages, { sender: 'bot', content: botReply, time: `${hoursRes}:${minutesRes}` }]);

      setMessageSent(false)

      sessionStorage.setItem(productId, JSON.stringify(messageRef));
      
      
      setUserInput('');
      
    } catch (error) {
      helpers.logToOutput('Error sending message:', error);

      // Handle error accordingly (e.g., show a user-friendly message)
    }
   
  };

  //<div className="context-menu" style={{ top: menuOptions.position.top, left: menuOptions.position.left }}>

  return (
    <>
      <div className='chat-container k-minh-100pc m-0 p-0 k-bg-white b-rad-5 b-shadow-on' >
        {config && config.KAI ? (
          <>
            <div className='chat-messages txt-12' onContextMenu={(e) => handleContextMenu( 'null', e)}>
              {messages && messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index} className={`p-1 chat-message ${msg.sender}`}>
                    <Row  className={`m-0 pt-1 ps-1 pe-1 pb-0 chat-message-bg ${msg.sender} k-center-content-hor-right b-rad-t-5`}>
                      <Col className='k-mw-33 m-0 p-1'>
                        <Row className='p-0 m-0'>
                          <Col className='p-0 m-0'>
                            { msg.sender === 'user' ? (
                              <img 
                                src={userIcon}
                                alt={msg.sender}
                                className="sender-icon k-mh-20 "
                              />
                            ) : (
                              <img 
                                src={botIcon}
                                alt={msg.sender}
                                className="sender-icon k-mh-20 "
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className='p-0 m-0'>
                          <Col className='p-0 m-0 txt-8'>
                            {msg.time}
                          </Col>
                        </Row>
                      </Col>
                      <Col className='p-1'>
                        {msg.content}
                      </Col>
                    </Row>
                    {index != 0  && msg.sender != 'user' ? (
                      <Row className={`m-0 pt-0 ps-1 pe-1 pb-0 chat-message-bg ${msg.sender} k-center-content-hor-right b-rad-b-5`}>
                        <Col className='col-auto p-0 pb-1 d-flex justify-content-end align-items-end'>
                          {/*<MdContentCopy 
                          className="copy-icon" 
                          style={{ cursor: 'pointer'}}
                          onClick={() => handleCopy(msg.content)}
                        />*/}
                        </Col>
                      </Row>
                    ) : (
                      <Row className={`m-0 p-0 b-rad-b-5 k-h-5 chat-message-bg ${msg.sender}`}><Col className={'m-0 p-0'}></Col></Row>
                    )}
                  </div>
                ))) : (
                <>
                </>
              )}
              {/* Invisible div at the end of messages */}
              {messageSent === true  && ( 
                <>
                  <Row>
                    <Col className='col-12'>
                      <Spinner className='pe-2' animation="border" variant="secondary" size="sm"/>
                      <span className='ps-1'>Thinking ...</span>
                    </Col>
                  </Row>
                  
                </>
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className='chat-input txt-12'>
              <input
                value={userInput}
                onChange={e => setUserInput(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();  // Prevents default behavior of a newline in some cases
                    handleSendMessage();
                    setUserInput('');  // Clear the input box
                  }
                }}
                placeholder="Type a message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <>
            <Row className='p-1 m-0'>
              <Col className='p-1 m-0'>
                <Alert variant="warning" className='txt-12 p-1'>
                  <Row className='p-0 m-0'>
                    <Col className='col-2 p-1 m-0'>
                      <img src={botIcon} className="sender-icon k-mh-30"/>
                    </Col>
                    <Col className='p-1 m-0'>
                      A.I. chat has not been enabled for your platform <br/><br/>
                      Please contact your systems administrator to enable this function
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </Row>
          </>
        )
        }
      </div>
      {isContextMenuVisible && (
        <div className="context-menu k-b-rad-20 k-bg-trans" style={{ top: menuOptions.position.top, left: menuOptions.position.left }}>
          <menus.ChatMenuPopup menuData={menuOptions} isUserAuthed={isUserAuthed}/>
        </div>
      )}
    </>
    
  );
};

export const ZendeskChatWrapper: React.FC = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=2d87fe27-e2f4-4475-9430-d289a7b74527';

    // Append the script to the body
    document.body.appendChild(script);

    // Optional: Clean up the script when the component unmounts
    return () => {
      if (document.getElementById('ze-snippet')) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return null; // This component doesn't render anything
};