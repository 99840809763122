// EnsureConfigLoaded.tsx
import React, { useEffect } from 'react';
import { useConfig } from '../../utilities/variables/configContext';

// Minimal spinner or loading placeholder
const LoadingScreen: React.FC = () => {
  return (
    <div 
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <div className="text-center">
        <p className="mb-3">Loading please wait...</p>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

const EnsureConfigLoaded: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { config, loading, error, fetchConfig } = useConfig();

  useEffect(() => {
    // If no config loaded yet AND not currently fetching, fetch it
    if (!config && !loading) {
      fetchConfig();
    }
  }, [config, loading, fetchConfig]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div>Error loading config: {error}</div>;
  }

  // If config is loaded, render children
  return <>{children}</>;
};

export default EnsureConfigLoaded;
